import ItemContent from '../item-content/ItemContent'

export default function Summary() {
  const content = (
    <div>
      <p style={{ textAlign: 'justify' }}>
        I have been developing products with web technologies
        <span> since 2010</span>. I really love coding and delivering software
        with the right quality, balancing business needs with
        <span> engineering and architecture</span> requirements.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span>I enjoy working in innovative products</span> that fit the users'
        needs and strongly believe in <span>continuous improvement</span>.
        That's why I always seek opportunities to learn and embrace
        <span> new challenges.</span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        Passionate about <span>system design</span>,
        <span> cloud architectures</span> and <span>software development</span>.
        <br />
        <br />I perform automated deployments using <span>Terraform</span>,
        <span>Bitbucket Pipelines</span>, or a combination of both
        <span> (CI/CD)</span>.
        <br />
      </p>
      <p style={{ textAlign: 'justify' }}>
        For static website creation, I utilize <span>AWS S3</span> and implement
        <span> Terraform</span> to replicate the site across different
        environments (dev, staging, prod).
        <br />I perform static code analysis locally and through
        <span> Bitbucket Pipelines</span>. Using <span>Docker</span>, I deploy a
        <span> SonarQube</span> server with the community version, then deploy
        this container to an <span>AWS EC2 instance</span>. To improve
        accessibility, I link it to a subdomain via <span>Cloudflare</span>.
      </p>
      <p style={{ textAlign: 'justify' }}>
        I've employed <span>CloudFlare</span>, specializing in
        <span> cybersecurity defense</span>, <span> NS configuration</span>, and
        <span> DNS management</span> (GoDaddy, Route 53 Hosted Zone), setting up
        a Google Workspace mail server.
      </p>
      <p style={{ textAlign: 'justify' }}>
        I've developed real-time point-of-sale systems,
        <span> implemented credit card payments (OpenPay)</span>, and have
        advanced skills in Angular, <span>React</span> (
        <span>hooks and Redux</span>), and <span>Vue</span> (using Pinia).
      </p>
      <p style={{ textAlign: 'justify' }}>
        I develop <span>Serverless Microservices</span> using
        <span> Python and NodeJS</span>, deploying them on
        <span> AWS Lambda</span> and orchestrating endpoints through
        <span> API Gateway</span>. Ensuring robust <span>security</span>, I
        employ a custom authorizer to
        <span> validate JWTs using the RS256 algorithm</span>
        and a <span>public key</span>. During login, I cryptographically sign
        the token with a <span>private key</span>. For specific endpoints
        without an authorizer, I implement <span>API key authentication </span>
        mechanisms.
      </p>
      <p style={{ textAlign: 'justify' }}>
        In the domain of database design and management, my proficiency extends
        to SQL databases, including SQL Server, <span>MySQL</span>,
        <span> PostgreSQL</span>, as well as NoSQL databases like
        <span> MongoDB</span> and DynamoDB.
      </p>
      <p style={{ textAlign: 'justify' }}>
        My <span>AWS services experience</span> includes CloudFormation, AWS
        Lambda, S3, EC2, <span>ECS Fargate</span>, EBS, SQS, SNS, CloudWatch,
        <span> EventBridge</span>, managed both through the
        <span> AWS console and Terraform</span>. With <span>AWS RDS</span>, I
        have experience in the installation and administration of PostgreSQL,
        MySQL, and
        <span> Aurora</span>.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Focused on <span>software design and development</span>, as well as
        <span> cloud architectures</span> for both <span>backend</span> and
        <span> frontend</span>. I also have expertise in
        <span> Linux/Unix </span>
        server administration and
        <span> EC2 instance</span> management in AWS.
      </p>
    </div>
  )
  return <ItemContent title='Personal statement' content={content} />
}
