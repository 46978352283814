import ItemContent from '../item-content/ItemContent'

const content = (
  <ul>
    <li>
      <span>Cloud (AWS, Google).</span>
    </li>
    <li>
      I implement infrastructure as code using <span>Terraform</span> (AWS
      Cloud, Packer) and <span>CloudFormation</span>.
    </li>
    <li>
      Contact Centers (<span>AWS Amazon Connect, Amazon Lex, Contact Lens</span>
      ).
    </li>
    <li>
      Development of TPV in real time, with a payment platform(
      <span>OpenPay API</span>).
    </li>
    <li>
      Geolocation (<span>google maps api v3</span>).
    </li>
    <li>
      Angular, <span>React (hooks, Redux)</span>, Vue (SPA, real time
      WebSockets, GraphQL).
    </li>
    <li>
      Mobile apps ionic-angular, <span>Flutter</span>
      ,&nbsp;
      <span>React Native</span> (iOs, Android).
    </li>

    <li>
      <span>
        Linux / Unix server management, Docker, AWS-EC2, AWS-EBS, ECS, EKS.
      </span>
    </li>
    <li>
      <span>
        Api's Rest (NodeJS(express) or Python(Fastapi, Flask, Django) in AWS EC2
        instances).
      </span>
    </li>
    <li>
      <span>
        Serverless microservices (AWS Lambda(NODE, PYTHON), AWS Api
        Gateway(Authorizers JsonWebTokens), AWS-SQS, AWS-SNS).
      </span>
    </li>
    <li>
      I automate container deployments on AWS using <span>ECR</span>,
      <span> ECS</span>, and <span>Fargate</span> for deploying monolithic
      applications.
    </li>
    <li>PHP (Codeigniter, Laravel).</li>
    <li>.NET (ASP C# / Visual Basic).</li>
    <li>
      Design (normalization) and administration of&nbsp;
      <span>SQL and NoSQL databases</span>
      (SqlServer, MySql, Postgresql, MongoDB, DynamoDB).
    </li>
    <li>
      <span>
        AWS (Lambda, Api Gateway, S3, SQS, Cloudwatch, RDS, EventBridge).
      </span>
    </li>
    <li>
      <span>Unit tests</span> and&nbsp;
      <span>Integration testing</span>(cypress).
    </li>
    <li>
      <span>CI/CD</span> Using pipelines, automated deployments with
      <span> Jenkins to AWS</span>.
    </li>
    <li>
      Using <span>Docker and dev-containers</span> for development in VS-Code.
    </li>
    <li>
      I work with <span>CloudFlare</span>, specializing in
      <span> cybersecurity defense</span>, setting up Name Servers
      <span> (NS)</span>, and managing DNS
      <span> (GoDaddy, Route 53 Hosted Zone)</span>, as well as configuring
      <span> Google Workspace email servers</span>.
    </li>
  </ul>
)

export default function CoreQualifications() {
  return <ItemContent title='CORE QUALIFICATIONS' content={content} />
}
