import './App.scss'
import AllContent from './components/all-content/AllContent'
import Header from './components/header/Header'

function App() {
  return (
    <div id='drag' className='cv instaFade wrap'>
      <div id='' className='quickFade delayFive'>
        <Header />
        <AllContent />
      </div>
    </div>
  )
}

export default App
