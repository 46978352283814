import ItemContent from 'components/item-content/ItemContent'

const content = (
  <>
    <article>
      <h3>GoNet USA - an Orion Innovation Company</h3>
      <p className='subDetails'>Google Cloud Developer at BBVA</p>
      <p className='subDetails'>February 2023 - At Present</p>
      <div className='more-details'>
        <p>
          <span>ACTIVITIES:</span>
        </p>
        <ul>
         <li>I am part of the Servicing Talent & Culture team at BBVA.</li>
         <li>Migrating applications developed with Java 8 to Java 11, 17, or to a new archetype defined by the BBVA Developer Team.</li>
         <li>Developing and maintaining applications built with Java (8, 11, 17), WordPress (version 4 to 6, on PHP version 4 to version 8), Python2, Python3 without a framework or using Flask.</li>
         <li>For new developments: "Java, Python3, NodeJs, Angular, Vue, WordPress".</li>
         <li>Using App Engine for deploying applications built with Java 8 or JAVA++ on Google Cloud Platform (GCP).</li>
         <li>CI/CD (Bitbucket Pipelines, Jenkins, SonarQube, Private Artifactory [jfrog]).</li>
         <li>Using SDKMAN and JENV to have different versions of Java installed on my machine, especially for updating legacy Java applications (Java 8 to 11 or 21).</li>
         <li>SENDGRID, Email Marketing.</li>
        </ul>
      </div>
    </article>
    <article>
      <h3>UBIQUIUS - Banpay.com</h3>
      <p className='subDetails'>Developer & Cloud Architect & DevOps</p>
      <p className='subDetails'>April 2023 - January 2024</p>
      <div className='more-details'>
        <p>
          <span>RESPONSIBILITIES:</span>
        </p>
        <ul>
          <li>
            Facilitated the onboarding of new team members by providing guidance
            and <span>training on technologies</span> like
            <span> AWS Lambda</span>, <span>API Gateway</span>, and
            <span> Amazon ECS</span>. I offered advice to help them quickly
            grasp and effectively use these tools in their daily tasks.
          </li>
          <li>
            Managed <span>users</span>, <span>groups</span>, <span>roles</span>,
            and <span>policies</span> in AWS Identity and Access Management
            <span> (IAM)</span> for accounts in development and staging
            environments.
          </li>
          <li>
            Automated the deployment of Lambda functions in development,
            staging, and production environments using
            <span> Bitbucket Pipelines</span>. Utilized <span>AWS CLI</span> and
            set up the <span>atlassian/aws-lambda-deploy</span> image in
            pipelines, handling infrastructure and code updates through
            <span> Terraform</span>. This simplified process made deployment
            operations more efficient.
          </li>

          <li>
            Implemented the creation and validation of <span>JWT tokens </span>
            using the <span>RS256</span> algorithm to enhance security in
            <span> API Gateway</span>. Configured a
            <span> custom authorizer</span> and the sign-in process using
            Python. Additionally, established a system where certificates are
            stored in an <span>AWS S3 bucket</span>, allowing secure security
            rotation.
          </li>

          <li>
            Developed services with <span>FastAPI</span>, using
            <span> Docker</span> and applying the <span>RS256</span> algorithm
            along with JWT authorization middleware on specific routes.
            <span>
              &nbsp;Automated deployment in Elastic Container Service with
              Fargate &nbsp;
            </span>
            using Bitbucket Pipelines, from image building and updating in
            <span> AWS ECR</span> to service update in <span>ECS</span>.
          </li>

          <li>
            Implemented static code analysis with <span>SonarQube</span>, using
            a Docker container. Deployed the container to an
            <span> AWS EC2 </span> instance and linked it to a
            <span> subdomain</span> on <span>Cloudflare</span>.
          </li>

          <li>
            Implemented AWS services, including <span>EventBridge</span>,
            <span> SNS</span>,<span> SQS</span>, and <span>Lambdas</span>, for
            specific <span>Cron Jobs</span>.
          </li>

          <li>
            Developed and published <span>layers</span> for Lambda functions in
            <span> Python</span> and <span>NodeJs</span>, using the
            <span> AWS CLI</span>.
          </li>

          <li>
            At <span>CloudFlare</span>, I set up name servers <span>(NS) </span>
            for domains registered with <span>GoDaddy</span> and
            <span> Route 53</span>, <span>managed DNS records</span>, linked
            <span> S3 Bucket URLs</span> and <span>AWS Load Balancers</span> to
            domains or subdomains in Cloudflare, and implemented configurations
            to resist <span>DDoS</span> and <span>TOR attacks</span>.
          </li>
        </ul>
      </div>
    </article>

    <article>
      <h3>Lumston</h3>
      <p className='subDetails'>Cloud Operations</p>
      <p className='subDetails'>July 2022 - April 2023</p>
      <div className='more-details'>
        <p>
          <span>TECHNOLOGIES:</span>
        </p>
        <ul>
          <li>
            Frontend (<span>Angular(typescrcipt)</span>, HTML, CSS, Tailwind).
          </li>
          <li>
            Backend (NodeJs, <span>NestJs</span>, Typescript, TypeOrm).
          </li>
          <li>
            Microservices serverless (AWS Lambda (<span>NodeJs, Python</span>),
            AWS ApiGateway, AWS RDS, AWS SNS, <span>AWS DynamoDB</span>,&nbsp;
            <span>MongoDB</span>, EventBridge, CloudWatch).
          </li>
          <li>
            <span>Amazon Connect (Contact center)</span>, Amazon Lex, Amazon
            Contact Lens. ( Create contact centers, programming the contact
            flow, recording calls to apply AI and analyze sentiments and improve
            the user experience)
          </li>
        </ul>
      </div>
    </article>
    <article>
      <h3>
        Clip <span>(Es la plataforma líder de pagos digitales y comercio)</span>
      </h3>
      <p className='subDetails'>Sr. Fullstack developer</p>
      <p className='subDetails'>December 2021 - May 2022</p>
      <div className='more-details'>
        <p>
          <span>TECHNOLOGIES:</span>
        </p>
        <ul>
          <li>
            Front (JavaScript, <span>React</span>, HTML, CSS,&nbsp;
            <span>Design system</span>).
          </li>
          <li>
            Microservices serverless (<span>AWS Lambda (NodeJs, Python)</span>
            ,&nbsp;<span>AWS ApiGateway</span>, AWS RDS, AWS DynamoDB,MongoDB).
          </li>
          <li>AWS CloudFormation.</li>
          <li>Git, GitHub, Jenkins.</li>
          <li>Java, Spring Boot.</li>
        </ul>
      </div>
    </article>
    <article>
      <h3>Numina Mx</h3>
      <p className='subDetails'>Full-stack developer</p>
      <p className='subDetails'>December 2019 - September 2021</p>
      <div className='more-details'>
        <p>
          <span>TECHNOLOGIES:</span>
        </p>
        <ul>
          <li>
            <span>HTML, CSS(Responsive web design, Flexbox, CSS Grid)</span>.
          </li>
          <li>
            JavaScript from&nbsp;
            <span>ES1</span> to&nbsp;
            <span>ES2020</span> (Angular,&nbsp;
            <span>React(hooks)</span>, Vue).
          </li>
          <li>
            <span>AWS (Lambda(Python3, NodeJs), Api Gateway, S3).</span>
          </li>
          <li>Terraform, Packer.</li>
          <li>Automated deployments to stage or production.</li>
          <li>
            Unit Testing and code quality check(<span>Sonarq</span>).
          </li>
        </ul>
        <p className='bottom-desc'>
          I developed this website: https://www.numina.mx/ using react,
          styled-components, only css, without using UI libraries.
        </p>
      </div>
    </article>
    <article>
      <h3>Envíoclick</h3>
      <p className='subDetails'>Full-stack developer</p>
      <p className='subDetails'>April 2019 - December 2019</p>
      <div className='more-details'>
        <p>
          <span>TECHNOLOGIES:</span>
        </p>
        <ul>
          <li>
            Migrate application made in (Laravel, vue js) to Microservices.
          </li>
          <li>
            JavaScript (Angular, React(hooks), Vue from laravel monolithic app).
          </li>
          <li>AWS (Lambda(Python3, NodeJs), Api Gateway, S3).</li>
          <li>
            Correction of bugs and development of features for app in Angular.
          </li>
          <li>Automated deployments to stage or production.</li>
          <li>Unit Testing and code quality check(Sonarq).</li>
        </ul>
        <p className='bottom-desc'>
          I developed this website: https://www.envioclick.com/mx using react,
          styled-components
        </p>
      </div>
    </article>
    <article>
      <h3>
        Copiloto Satelital <span>Grupo Michelin (Ciudad de México)</span>
      </h3>
      <p className='subDetails'>Full-stack developer</p>
      <p className='subDetails'>May 2018 - April 2019</p>
      <div className='more-details'>
        <p>
          <span>TECHNOLOGIES:</span>
        </p>
        <ul>
          <li>
            Design and development of a multiplatform application
            (ionic-angular), currently for android available in the PlayStore
            (Back-end / Front-end, store publication).
          </li>
          <li>.Net webApi (apiRestFul), NodeJS.</li>
          <li>SqlServer (Stored procedure).</li>
          <li>Git, BitBucket.</li>
          <li>
            Bug fixes and improvements to the vehicle monitoring platform with
            GPS.
          </li>
          <li>WebServices, WCF (.net, asp, C#, VB, SQL Server).</li>
          <li>Microservices (NodeJS, MongoDB(mongoose), SQL Server).</li>
        </ul>
      </div>
    </article>

    <article>
      <h3>
        Mx-Race <span>(Ciudad de México)</span>
      </h3>
      <p className='subDetails'>Developer</p>
      <p className='subDetails'>May 2015 - April 2018</p>
      <div className='more-details'>
        <p>
          <span>TECHNOLOGIES:</span>
        </p>
        <ul>
          <li>
            Design and development of an electronic timing system (.NET, SQL
            Server).
          </li>
          <li>
            Web Platform (html5, css3, js, bootstrap)), publication of results
            in real time (WebServices PHP).
          </li>
          <li>
            Design, development and implementation of an Enrollment System for
            athletic races charged to a credit / debit card.
          </li>
          <li>
            Development of registration platforms for employees of private
            sports races (Grupo BAL, NISSAN Unbreakable, Deloitte, Grupo DESC -
            KUO, Dupont, Sagarpa, GEEP, BANXICO, Metlife).
          </li>
        </ul>

        <p>https://www.mx-race.com/eventos/</p>
        <p>https://time-mx.com/misinscripciones/</p>
        <p>https://time-mx.com/resultados/unbreakable/2018/veracruz/</p>
      </div>
    </article>
    <div className='clear'></div>
  </>
)
export default function WorkExperience() {
  return <ItemContent title='Work Experience' content={content} />
}
