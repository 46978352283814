import edgImage from 'assets/images/edg-0118.jpg';

export default function Header() {
  return (
    <div className='mainDetails'>
      <div id='headshot' className=''>
        <img src={edgImage} title="Hi, I'm Edgar!" alt='Edgar Colón' />
      </div>

      <div id='name'>
        <h1 className='quickFade delayTwo'>Edgar Colón B.</h1>
        <h4 className='quickFade delayThree'>Senior Software Engineer</h4>
        <h4 className='quickFade delayThree'>10++ years of experience</h4>
      </div>

      <div id='contactDetails' className='quickFade delayFour'>
        <ul>
          <li>
            <a
              href='https://www.linkedin.com/in/edgar-colon-barrientos/'
              title='LinkedIn'
            >
              <i className='fa fa-linkedin-square' aria-hidden='true'></i>
            </a>
          </li>
        </ul>
      </div>
      <div className='clear'></div>
    </div>
  );
}
