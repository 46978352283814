import ItemContent from 'components/item-content/ItemContent'
import './styles.scss'

export default function Download() {
  const content = (
    <>
      <article>
        <a
          href='https://edgar-colon.mx/edgar-colon-cv-28082024.pdf'
          className='btn-slide2'
          target='_blank'
          rel='noreferrer'
        >
          <span className='circle2'>
            <i className='fa fa-download'></i>
          </span>
          <span className='title2'>Download CV</span>
          <span className='title-hover2'>Click here</span>
        </a>
      </article>
      <div className='clear'></div>
    </>
  )
  return <ItemContent title=' ' content={content} />
}
