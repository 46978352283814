import ItemContent from 'components/item-content/ItemContent'

export default function Skills() {
  const content = (
    <>
      <>
        Critical Thinking, Problem Solving, Time Management, Effective
        Communication, Teamwork, Resolving technical issues, Faster learner,
        Learn mindset, Coaching, Mentoring , Active listening, Abstract
        thinking, Honesty, Adaptability.
      </>
      <h4>FRONT END</h4>
      <ul>
        <li>
          I have advanced knowledge on <span>HTML5</span>
          ,&nbsp;
          <span>CSS3</span> and&nbsp;
          <span>JavaScript</span> (from&nbsp;
          <span>ES1</span> to&nbsp;
          <span>ES2020</span>).
        </li>
        <li>
          I have used <span>typescript</span> in both the frontend and backend.
        </li>
        <li>
          <span>
            CSS (Advanced knowledge): Flexbox, CSS Grid, responsive design
            (Tailwind CSS, Bootstrap).
          </span>
        </li>
        <li>
          <span>React Js (Advanced knowledge)</span>: Creating Class and
          Functional components,&nbsp;
          <span>hooks</span>,&nbsp;
          <span>redux</span>, hoc, styled-components.
        </li>
        <li>
          <span>Angular (Advanced knowledge):</span>
          &nbsp;Creating components, modules, services from angular cli, using
          redux or services to share the state, rxjs, protect the routes using
          guards.
        </li>
        <li>
          Vue (Advanced knowledge): Creating components, sharing the state using
          vuex(actions, mutations, getters, store).
        </li>
        <li>
          Using <span>AWS S3</span> to deploy Web App and&nbsp;
          <span>Cloudflare DNS</span>.
        </li>
      </ul>
      <h4>BACK END</h4>
      <ul>
        <li>
          <span>Serverless microservices:</span> (
          <span>AWS Lambda(NODE, PYTHON)</span>, AWS Api Gateway(Authorizers) ,{' '}
          <span>AWS-SQS</span>).
        </li>
        <li>
          <span>Python:</span> Real time apps using socket.io,{' '}
          <span>FastApi</span>, Flask, Django,&nbsp;
          <span>AWS Lambda(serverless)</span>.
        </li>
        <li>
          <span>Node:</span> Express (socket.io),&nbsp;
          <span>NestJs</span>,&nbsp;
          <span>AWS Lambda(Serverless)</span>, using typescript.
        </li>
        <li>PHP: Laravel.</li>
        <li>Java: Spring, Spring Boot, Spring Data, Spring Security.</li>
        <li>
          Servers: <span>Managing EC2 instances</span>, VPS through SSH
          connection,&nbsp;
          <span>Docker containers.</span>
        </li>
      </ul>
      <h4>SQL DATABASES</h4>
      <ul>
        <li>MYSQL</li>
        <li>POSTGRESQL</li>
        <li>SQL SERVER</li>
        <li>AURORA (mysql - postgresql)</li>
      </ul>
      <h4>NO-SQL DATABASES</h4>
      <ul>
        <li>Mongo DB</li>
        <li>Dynamo DB</li>
      </ul>
      <div className='clear'></div>
    </>
  )
  return <ItemContent title='SKILLS' content={content} />
}
