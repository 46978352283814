export default function ItemContent(props: any): JSX.Element {
  return (
    <section>
      <article>
        <div className='sectionTitle'>
          <h1>{props.title}</h1>
        </div>
        <div className='sectionContent'>{props.content}</div>
      </article>
      <div className='clear'></div>
    </section>
  );
}
