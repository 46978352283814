import Contact from 'components/contact/Contact'
import CoreQualifications from 'components/core-qualifications/CoreQualifications'
import Skills from 'components/skills/Skills'
import Summary from 'components/summary/Summary'
import WorkExperience from 'components/work-experience/WorkExperience'
import Download from '../Download/Download'
import EconomicPerception from 'components/economic-perception/EconomicPerception'

export default function AllContent() {
  return (
    <div id='mainArea'>
      <Summary />
      <CoreQualifications />
      <Skills />
      <WorkExperience />
      <Contact />
      {/* <EconomicPerception /> */}
      <Download />
    </div>
  )
}
