import ItemContent from 'components/item-content/ItemContent'

const content = (
  <>
    <article style={{ marginTop: '-10px' }}>
      It ranges from <span>$70,000</span> to <span>$90,000</span> net mexican
      pesos per month after taxes. <br />
      The specific amount will depend on the work arrangement, whether it's
      <span> Home Office</span>, Hybrid, or In-Person (Office-based).
    </article>
    <div className='clear'></div>
  </>
)

export default function EconomicPerception() {
  return <ItemContent title='Salary Expectation' content={content} />
}
