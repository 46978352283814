import ItemContent from 'components/item-content/ItemContent'
import './styles.scss'
const content = (
  <>
    <article>
      <div className='contact'>
        <div className='pad-l-30'>
          <span className='fa fa-phone circle' aria-hidden='true'></span>
          (+52) 5585745825
          <br />
          <span className='fa fa-phone circle' aria-hidden='true'></span>
          (+52) 7444328340
          <br />
          <span className='fa fa-map-marker circle' aria-hidden='true'></span>
          Ciudad de México
          <br />
          <span
            className='fa fa-solid fa-envelope circle'
            aria-hidden='true'
          ></span>
          <a href='mailto:info@edgar-colon.mx'>edgar.colonb@gmail.com</a> <br />
        </div>
      </div>
    </article>
    <div className='clear'></div>
  </>
)
export default function Contact() {
  return <ItemContent title='Contact information' content={content} />
}
